// Map settings
export const ZOOM = 2;
export const CENTER = [0, 0];
export const MAX_BOUNDS = [[90, -180], [-90, 180]];

// Imported shapes will be red, so they can be easily
// differentiated from drawn shapes (which are blue).
export const IMPORT_STYLE = {
  fillColor: 'red',
  fillOpacity: 0.3,
  weight: 1,
  color: 'red'
};
